import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import { BgImage } from "gbimage-bridge";

import { getImage } from "gatsby-plugin-image";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import AgenceCoordonnees from "../../components/agenceInfo/agenceCoordonnees";
import GoogleMapsEmbed from "../../components/googleMapsEmbed";

import location from "../../images/agence-info/location.svg";
import "./style.scss";
import PersonCard from "../../components/card/personCard/personCard";

const AgencePage = ({ data }) => {
  const agence = data.strapiAgence;

  const img = agence.Image ? agence.Image.localFile.publicURL : "";
  const image = getImage(agence.Image?.localFile);

  const inspecteursAuxiliaire = agence.Auxiliaire.filter((p) => p.Inspecteur);
  const nonInspecteursAuxiliaire = agence.Auxiliaire.filter((p) => !p.Inspecteur);
  const inspecteursAuxiliaireVie = agence.Auxiliaire_Vie.filter((p) => p.Inspecteur);
  const nonInspecteursAuxiliaireVie = agence.Auxiliaire_Vie.filter((p) => !p.Inspecteur);

  return (
    <>
      <Helmet>
        <body className="article" />
      </Helmet>
      <Layout>
        <SEO title={agence.Nom} image={img} />
        <BgImage image={image}>
          <section
            className="bloc-imageHead"
            id="header"
          >
            <h1>
              Bienvenue à <br/>
              <strong>{agence.Nom}</strong>
            </h1>
            <div />
          </section>
        </BgImage>

        <section className="bloc-infos">
          <h2>
            Les infos <br />
            <strong>pratiques</strong>
          </h2>

          <div className="bloc-infos-full-ctn">
            <GoogleMapsEmbed
              className={"bloc-infos-full-ctn-map"}
              ville={agence.Adresse.Ville}
              google_map={agence.Google_Map}
              rue={agence.Adresse.Rue}
              heightIframe={300}
              heightMap={250}
              widthMap={450}
              screenMobile={0}
            />
            <a aria-label="Google Maps" target="_blank" href={"https://maps.google.com/maps?&hl=fr&q=" + (agence.Google_Map ? agence.Google_Map : (agence.Adresse.Ville + " " + agence.Adresse.Rue + " L\'Auxiliaire")) +"&t=&z=15&ie=UTF8&iwloc=B"} className="bloc-infos-full-ctn-content">
              <img className="icon" src={location} alt="Pin de localisation" />
              <div>
                <p>{agence.Adresse.Rue}</p>
                {agence.Adresse.Complement && <p>{agence.Adresse.Complement}</p>}
                <p>{agence.Adresse.Ville}</p>
              </div>
            </a>
          </div>

          {agence.Auxiliaire.length > 0 && (
            <section className={agence.Auxiliaire.length === 1 ? "reduced" : undefined}>
              <h3>L'Auxiliaire</h3>

              <div>
                <div className="infos">
                  <AgenceCoordonnees coordonnees={agence.Auxiliaire_Coordonnees} includeContactRef />
                </div>
                {inspecteursAuxiliaire.map((p, i) => <PersonCard key={"pc-insp" + i} className="person-card" person={p.Personne} />)}
                {inspecteursAuxiliaire.length > 1 && <div className="person-card-breakpoint"></div>}
                {nonInspecteursAuxiliaire.map((p, i) => <PersonCard key={"pc-ninsp" + i} className="person-card" person={p.Personne} />)}
              </div>
            </section>
          )}

          {agence.Auxiliaire_Vie.length > 0 && (
            <section
              id="auxiliaire-vie" className={agence.Auxiliaire_Vie.length === 1 ? "reduced" : undefined}>
              <h3>L'Auxiliaire Vie</h3>

              <div>
                <div className="infos">
                  <AgenceCoordonnees coordonnees={agence.Auxiliaire_Vie_Coordonnees} includeContactRef />
                </div>
                {inspecteursAuxiliaireVie.map((p, i) => <PersonCard key={"pc-vie-insp" + i} className="person-card" person={p.Personne} />)}
                {inspecteursAuxiliaireVie.length > 1 && <div className="person-card-breakpoint"></div>}
                {nonInspecteursAuxiliaireVie.map((p, i) => <PersonCard key={"pc-vie-ninsp" + i} className="person-card" person={p.Personne} />)}
              </div>
            </section>
          )}
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query agencePageQuery($id: String!) {
    strapiAgence(id: { eq: $id }) {
      Adresse {
        Rue
        Ville
        Complement
      }
      Nom
      Google_Map
      id
      Auxiliaire {
        Personne {
          Nom
          Poste
          Prenom
          Photo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        Inspecteur
      }
      Auxiliaire_Vie {
        Personne {
          Nom
          Poste
          Prenom
          Photo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        Inspecteur
      }
      Auxiliaire_Coordonnees{
        Horaires {
          Heures
          Jours
        }
        Telephone
      }
      Auxiliaire_Vie_Coordonnees{
        Horaires {
          Heures
          Jours
        }
        Telephone
      }
      Image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`;

export default AgencePage;
